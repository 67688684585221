import { BRAZE_CHANGE_USER_EVENT } from "../changeUserEvent";

export const hasBrazeChangeUserEvent = () => {
  if (typeof window !== "object") {
    return;
  }

  const dataLayer = window.dataLayer || [];
  return !!dataLayer.find((item) => {
    return item.event === BRAZE_CHANGE_USER_EVENT;
  });
};
