import { destinationsQuery } from "@graphql/types";
import { Section } from "@newt/ui";
import { getPopularDestinationsByIds } from "@utils/useDestinations";
import { useMemo } from "react";
import { DestinationDetailLinksCarousel } from "./DestinationDetailLinksCarousel";

interface SectionDestinationDetailLinksProps {
  destinationDetailLinks: destinationsQuery["destinations"]["edges"];
}

// https://docs.google.com/spreadsheets/d/1-TaXnfk14spFGqwdjYpWH3DDqHzHFZmL1haG1hjJ1rQ/edit#gid=1645921588
export const DESTINATION_DETAIL_LINK_IDS = [
  3, 8, 152, 2, 9, 42, 47, 4, 6, 48, 7, 31, 5, 57, 98, 40,
];

export const SectionDestinationDetailLinks = (
  props: SectionDestinationDetailLinksProps
) => {
  const { destinationDetailLinks } = props;
  const popularDestinations = useMemo(
    () =>
      getPopularDestinationsByIds(
        DESTINATION_DETAIL_LINK_IDS,
        destinationDetailLinks
      ),
    [destinationDetailLinks]
  );

  return (
    <Section
      title="🌏 エリアの情報をみる"
      description="人気エリアのおすすめスポットやツアーをチェック！旅行に役立つ記事や動画も紹介しています。"
      textAlign={{
        mobile: "left",
        tablet: "left",
      }}
    >
      <DestinationDetailLinksCarousel items={popularDestinations} />
    </Section>
  );
};
