import { useEffect, useRef } from "react";
import { MarketingService } from "@utils/marketing/MarketingService";
import { hasBrazeChangeUserEvent } from "./hasBrazeChangeUserEvent";
import { PageViewBrazeEventProps } from "./pageViewEvent";

export const useBrazePageView = (variables: PageViewBrazeEventProps) => {
  const fired = useRef(false);
  const didChangeUserFire = hasBrazeChangeUserEvent();

  useEffect(() => {
    if (!fired.current && didChangeUserFire) {
      MarketingService.logEvent.brazePageView(variables);
      fired.current = true;
    }
  }, [variables, didChangeUserFire]);
};
