import { SearchPageQuery } from "@components/pages/search/query";
import {
  Destination,
  DestinationStatus,
  DestinationType,
  destinationsForTopQuery,
} from "@graphql/types";
import { CarouselDisplay, Section, Text, useBreakPoints } from "@newt/ui";
import { ROUTES } from "@routes";
import Image from "next/image";
import Link from "next/link";
import styles from "./SectionPopularDestinations.module.css";
import { useAuth } from "@utils/auth";

interface SectionPopularDestinationsProps {
  destinations: destinationsForTopQuery;
  searchPageQuery?: SearchPageQuery;
}

type AreaGroupItem = Pick<Destination, "id" | "name" | "code"> & {
  image: string;
  priceMin: string;
  type: DestinationType;
  parent?: Pick<Destination, "code">;
};

export function SectionPopularDestinations(
  props: SectionPopularDestinationsProps
) {
  const { destinations, searchPageQuery } = props;
  const breakPoints = useBreakPoints();
  const { user } = useAuth();

  const areaGroups = destinations.destinations.edges.map((destination) => {
    return {
      id: String(destination.node.id),
      name: destination.node.name,
      items: destination.node.children.flatMap((child) => {
        const items: AreaGroupItem[] =
          child.status === DestinationStatus.PUBLIC
            ? [
                {
                  id: child.id,
                  name: child.name,
                  code: child.code,
                  image: child.mainImage?.url || "",
                  priceMin: child.priceMin
                    ? `￥${child.priceMin?.toLocaleString()}〜`
                    : "",
                  type: DestinationType.COUNTRY,
                },
              ]
            : [];

        child.children.forEach((grandChild) => {
          if (
            child.name !== grandChild.name &&
            grandChild.status === DestinationStatus.PUBLIC
          ) {
            items.push({
              id: grandChild.id,
              name: grandChild.name,
              code: grandChild.code,
              image: grandChild.mainImage?.url || "",
              priceMin: grandChild.priceMin
                ? `￥${grandChild.priceMin?.toLocaleString()}〜`
                : "",
              parent: { code: child.code },
              type: DestinationType.CITY,
            });
          }
        });

        return items;
      }),
    };
  });

  const itemWidth = breakPoints.tabletWithDown ? 96 : 108;
  const itemGap = breakPoints.tabletWithDown ? 16 : 24;

  return (
    <div className={styles.root}>
      {areaGroups.map((area) => (
        <Section
          key={area.id}
          title={`${area.name}のツアー`}
          textAlign={{ mobile: "left", tablet: "left" }}
        >
          <div className={styles.items}>
            <CarouselDisplay
              itemWidth={itemWidth + itemGap}
              show={8}
              slide={8}
              items={area.items.map((destination) => (
                <Link
                  key={destination.code}
                  href={
                    user
                      ? ROUTES.search({
                          ...searchPageQuery,
                          destinationIds: [destination.id],
                        })
                      : destination.type === DestinationType.COUNTRY
                        ? ROUTES.tourCountry(destination)
                        : ROUTES.tourCity(destination)
                  }
                  prefetch={false}
                >
                  <div className={styles.item}>
                    <Image
                      src={destination.image || ""}
                      alt={destination.name}
                      width={itemWidth}
                      height={itemWidth}
                      style={{ objectFit: "cover" }}
                    />
                    <div className={styles.destinationName}>
                      <Text
                        weight="bold"
                        size={{ mobile: "sm", tablet: "base" }}
                        truncate
                      >
                        {destination.name}
                      </Text>
                      <Text
                        weight="bold"
                        size={{ mobile: "sm", tablet: "base" }}
                        truncate
                        color={"gray"}
                      >
                        {destination.priceMin}
                      </Text>
                    </div>
                  </div>
                </Link>
              ))}
            />
          </div>
        </Section>
      ))}
    </div>
  );
}
