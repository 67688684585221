import { useBreakPoints } from "@newt/ui";
import { ROUTES } from "@routes";
import { MarketingService } from "@utils/marketing";
import Image from "next/image";
import Link from "next/link";
import { imgixLoader } from "@utils/imgixLoader";
import styles from "./BannerFriendCode.module.css";

const BANNER_IMAGES = {
  SP: {
    text: "https://newt.imgix.net/product/friend_code_text_sp.png",
    button: "https://newt.imgix.net/product/friend_code_button_sp.png",
  },
  PC: {
    text: "https://newt.imgix.net/product/friend_code_text_pc.png",
    button: "https://newt.imgix.net/product/friend_code_button_pc.png",
  },
};

const BG_IMAGE = "https://newt.imgix.net/product/signup_background.png";

export const BannerFriendCode = () => {
  const { laptopWithUp } = useBreakPoints();

  return (
    <Link
      className={styles.link}
      href={ROUTES.friendCode()}
      onClick={() => {
        MarketingService.logEvent.viewModule({
          module_type: "referral_banner",
          view_referral_banner_count: 1,
        });
      }}
      prefetch={false}
    >
      <Image
        alt="背景画像"
        src={BG_IMAGE}
        width={1080}
        height={100}
        className={styles.bgImage}
        loader={imgixLoader}
        quality={90}
      />
      {laptopWithUp ? (
        <>
          <Image
            alt="友だちを紹介して5000ポイントゲット"
            src={BANNER_IMAGES.PC.text}
            width={372}
            height={39}
            style={{ objectFit: "contain" }}
            loader={imgixLoader}
            quality={90}
          />
          <Image
            alt="くわしくみる"
            src={BANNER_IMAGES.PC.button}
            width={210}
            height={32}
            style={{ objectFit: "contain" }}
            loader={imgixLoader}
            quality={90}
          />
        </>
      ) : (
        <>
          <Image
            alt="友だちを紹介して5000ポイントゲット"
            src={BANNER_IMAGES.SP.text}
            width={287}
            height={33}
            style={{ objectFit: "contain" }}
            loader={imgixLoader}
          />
          <Image
            alt="くわしくみる"
            src={BANNER_IMAGES.SP.button}
            width={169}
            height={31}
            style={{ objectFit: "contain" }}
            loader={imgixLoader}
          />
        </>
      )}
    </Link>
  );
};
