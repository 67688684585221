import { Container, useBreakPoints } from "@newt/ui";
import { ROUTES } from "@routes";
import { MarketingService } from "@utils/marketing";
import Image from "next/image";
import Link from "next/link";
import styles from "./SignInBanner.module.css";

export const SignInBanner = () => {
  const { laptopWithUp } = useBreakPoints();

  return (
    <Container>
      <Link
        className={styles.link}
        href={ROUTES.signin.index({ previousModuleType: "auth_banner" })}
        onClick={() => {
          MarketingService.logEvent.viewModule({
            module_type: "auth_banner",
            view_auth_banner_count: 1,
          });
        }}
        prefetch={false} // avoid prefetch huge size library, libphonenumber TODO: replace it.
      >
        {laptopWithUp ? (
          <>
            <Image
              alt="会員限定のおトクな情報をお届け"
              src="https://newt.imgix.net/product/signup_text.png"
              width={343}
              height={44}
            />
            <Image
              alt="無料で会員登録"
              src="https://newt.imgix.net/product/signup_Button.png"
              width={242}
              height={44}
            />
          </>
        ) : (
          <>
            <Image
              alt="会員限定のおトクな情報をお届け"
              src="https://newt.imgix.net/product/signup_text.png"
              width={266}
              height={33}
            />
            <Image
              alt="無料で会員登録"
              src="https://newt.imgix.net/product/signup_Button.png"
              width={220}
              height={40}
            />
          </>
        )}
      </Link>
    </Container>
  );
};
