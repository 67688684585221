import { DestinationDetailLink } from "@components/common";
import { DestinationDetailLink_DestinationFragment } from "@graphql/types";
import { Icon, useBreakPoints } from "@newt/ui";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./DestinationDetailLinksCarousel.module.css";

interface DestinationDetailLinksCarouselProps {
  items: DestinationDetailLink_DestinationFragment[];
}

const LAPTOP_CARD_WIDTH = 504;
const MOBILE_CARD_WIDTH = 304;
const ITEM_GAP = 24;
const ITEMS_PER_PAGE = 8;

export const DestinationDetailLinksCarousel: FC<
  DestinationDetailLinksCarouselProps
> = ({ items }) => {
  const { laptopWithUp, tabletWithUp } = useBreakPoints();
  const [cursor, setCursor] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);
  const cardWidth = tabletWithUp ? LAPTOP_CARD_WIDTH : MOBILE_CARD_WIDTH;
  const pages = Math.ceil(items.length / ITEMS_PER_PAGE) - 1;

  useEffect(() => {
    if (laptopWithUp && divRef.current) {
      divRef.current.scrollLeft = 0;
    }
    if (!laptopWithUp) {
      setCursor(0);
    }
  }, [laptopWithUp, setCursor]);

  const onClickLeftButton = useCallback(() => {
    setCursor((state) => Math.max(state - 1, 0));
  }, [setCursor]);

  const onClickRightButton = useCallback(() => {
    setCursor((state) => Math.min(state + 1, pages));
  }, [pages, setCursor]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.leftButtonContainer}></div>
      <div className={styles.carousel} ref={divRef}>
        <ul
          className={styles.items}
          style={{
            transform: `translateX(${
              laptopWithUp ? (cardWidth + ITEM_GAP) * cursor * 2 * -1 : 0
            }px)`,
          }}
        >
          {items.map((item) => (
            <li className={styles.item} key={item.id}>
              <DestinationDetailLink fragment={item} width={cardWidth} />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.rightButtonContainer}>
        {cursor > 0 && (
          <button className={styles.leftButton} onClick={onClickLeftButton}>
            <Icon icon="chevronLeft" color="gray-50" />
          </button>
        )}
        {cursor < pages && (
          <button className={styles.rightButton} onClick={onClickRightButton}>
            <Icon icon="chevronRight" color="gray-50" />
          </button>
        )}
      </div>
    </div>
  );
};
