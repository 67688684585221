import { FC } from "react";
import { ROUTES } from "@routes";
import { GlobalNavList } from "@newt/ui";
import styles from "./GlobalNavInSearchBox.module.css";

export const GlobalNavInSearchBox: FC = () => {
  const NAV_ITEMS = [
    {
      icon: "tour",
      label: "海外ツアー",
      isActive: true,
      href: ROUTES.home(),
    },
    {
      icon: "hotel",
      label: "海外ホテル",
      isActive: false,
      href: ROUTES.hotelApp.top(),
    },
  ];

  return (
    <GlobalNavList
      className={styles.navList}
      itemClassName={styles.listItem}
      items={NAV_ITEMS}
    />
  );
};
